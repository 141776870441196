.slick-prev {
  left: 3px;
  z-index: 1;
}
.slick-next {
  right: 3px;
  z-index: 1;
}
.slick-arrow {
  background: rgba(0, 0, 0, 0.6);
}
.slick-arrow:hover {
  background: rgba(0, 0, 0, 0.6);
}
.slick-arrow:focus {
  background: rgba(0, 0, 0, 0.6);
}
.button {
  background-color: #00558b;
  padding: 10px 20px;
  margin: 0px 20px;
  border: none;
  color: white;
  font-size: 20px;
  border-radius: 5px;
  min-height: 45px;
}
.slide {
  position: relative;
  text-align: center;
}
.slide-container {
  display: inline-block;
}
.slide-container img {
  width: 100%;
}
.slide__caption {
  bottom: 0;
  min-height: 80px;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 2;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
}
.slick-dots {
  display: block;
  width: 100%;
  margin: 0;
  list-style: none;
  text-align: center;
}
.slide__caption {
  min-height: 60px;
}
